<template>
  <div>
    <div class="fixed top-0 left-0 bottom-0 w-[250px] hidden lg:block">
      <SideNav/>
    </div>

    <div class="h-14 left-0 lg:left-[250px] shadow-sm z-50 fixed top-0 right-0 bg-white border-b flex gap-3 justify-between items-center px-4">

      <div class="flex items-center gap-4">
        <button @click="sideNavModal.open()" class="h-14 w-10 flex items-center justify-start lg:hidden"><i class="material-icons text-[32px]">menu</i></button>

        <slot name="topNavLeft">
          <search-form/>
        </slot>

        <div class="bg-[#C00000] rounded-md cursor-pointer text-white lg:flex hidden" v-if="currentMerchant && currentMerchant.status === 'building'" @click="$router.push({name:'onboarding'})">
          <div class="h-9 flex items-center px-3">
            <i class="material-icons mr-2 text-xl">engineering</i>
            <span class="text-sm font-medium">{{ $t('nav.in_construction') }}</span>
          </div>
          <button class="text-sm h-9 border-l font-medium border-red-300 px-3">{{ $t('cta.know_more') }}</button>
        </div>

        <button class="bg-primary text-white hidden lg:flex items-center h-9 px-3 gap-2 rounded-md" v-if="currentMerchant && currentMerchant.status === 'activated' && currentMerchant.hasOptimizationToDo">
          <i class="material-icons text-xl">construction</i>
          <span class="text-sm font-medium">{{ $t('nav.optimization') }}</span>
          <i class="material-icons text-lg">arrow_forward</i>
        </button>
        <div class="w-5 sm:hidden block"></div>
      </div>

      <div class="flex sm:hidden text-center items-center leading-tight" v-if="title">
        <strong>{{title}}</strong>
      </div>

      <div class="flex items-center gap-5">
        <slot name="topNavRight"></slot>

        <div id="tour_needHelp" class="h-8 w-5 sm:w-auto flex items-center justify-center">
          <button class="underline text-sm text-blue-700 font-medium hidden sm:block" @click="needHelp">{{$t('nav.need_help')}}</button>
          <button class="text-sm text-primary font-medium sm:hidden block flex items-center justify-center" @click="needHelp"><i class="material-icons">live_help</i></button>
        </div>

        <div class="h-10 w-10 bg-primary overflow-hidden rounded-full" v-if="!currentAccount || currentAccount && !currentAccount.avatar_small" @click="$router.push({name: 'settings_account'})">
          <img src="/images/logo_cut.png" class="pt-1" alt="">
        </div>
        <div v-else class="cursor-pointer shadow w-10 h-10 rounded-full flex justify-center items-center bg-cover bg-center"
             @click="$router.push({name: 'settings_account'})"
             :style="'background-image: url('+currentAccount.avatar_small+')'"
             v-if="currentAccount.avatar_small">
        </div>

      </div>
    </div>

    <div class="pt-14 pl-0 lg:pl-[250px] right-0 bottom-0 bg-gray-50" id="layout" :class="{'overflow-y-auto':scrollY}">

      <slot name="top"></slot>

      <slot name="page-content">
        <div class="px-2 py-4 md:px-6">


          <div class="flex justify-between items-center flex-col md:flex-row gap-4 pb-4" :class="[fixedAction ? 'z-50 bg-gray-50 md:fixed top-14 left-0 lg:left-[250px] right-0 px-6 pt-4' : '']">

            <div class="flex-1 flex items-center justify-start">
              <button v-if="hasBackButton" class="hidden sm:flex items-center justify-center bg-white border px-4 py-1 gap-2 mr-3 rounded-md hover:bg-gray-50"
                      @click="$router.back()"><i class="text-lg material-icons">arrow_back</i>Retour</button>
              <span class="text-2xl block uppercase font-bold hidden sm:block mr-3">{{ title }}</span>
              <slot name="afterTitle"></slot>
            </div>



            <div class="flex items-center flex-wrap gap-2">
              <slot name="actions"></slot>
            </div>
          </div>


          <div class="flex justify-center items-center flex-col my-10" v-if="isLoading||!currentMerchant">
            <loader/>
            <small class="mt-4">{{ $t('system.loading') }}</small>
          </div>
          <div v-else>
            <slot></slot>
          </div>
        </div>
      </slot>


    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SideNav from "@/components/Layouts/SideNav.vue";
import {useModal} from "vue-final-modal";
import SideNavSliding from "@/components/Ui/Modals/SideNavSliding.vue";
import EventBus from "@/EventBus";
import SearchForm from "@/components/Layouts/SearchForm.vue";
import {showChat} from "@/services/SystemService";
import {hasTourAvailable} from "@/services/TourService";

export default {
  components: {SearchForm, SideNav},
  props: {
    fixedAction: {
      default: false,
      type: Boolean
    },
    hasBackButton: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    scrollY: {
      type: Boolean,
      default: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  setup(){
    const sideNavModal = useModal({component: SideNavSliding});
    return {sideNavModal}
  },
  computed: {
    ...mapGetters({
      currentMerchant: 'currentMerchant',
      currentAccount: 'account/account'
    })
  },
  methods: {
    ...mapActions(['logoutAction']),
    needHelp(){
      if(!hasTourAvailable(this.$route.name)) showChat();
      else EventBus.emit('resetTour');
    },
    async logout(){
      await this.logoutAction();
      this.$router.push({name: 'login'});
    }
  },
  mounted() {
    // Update page title
    if (this.title) {
      document.title = this.title + ' - Backoffice Delicity';
    }
  }
}
</script>
